<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>
          측정시간 :
          {{
            filtered_temp_sensor_list.length > 0
              ? yyyyMMddHHmmss(
                  new Date(filtered_temp_sensor_list[0].create_time),
                )
              : ''
          }}
        </h5>
        <div class="btn_wrap">
          <button
            class="btn_sub1 refresh_btn"
            @click="
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
            "
          >
            재조회
          </button>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? `닫기` : '전체화면' }}
          </button>
        </div>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_body">
          <div
            class="monitor_card"
            :class="{ warning_card: !checkWorkPlaceState }"
            v-for="(item, index) in filtered_temp_sensor_list"
            :key="index"
          >
            <h2>{{ item.name.split('온습도센서')[0] }}</h2>
            <div class="gag_periods">
              <div></div>
              <span
                :class="{
                  normal_point: checkWorkPlaceState,
                  warning_point: checkWorkPlaceState == false,
                }"
                >{{ checkWorkPlaceState ? '정상' : '경고' }}</span
              >
            </div>
            <p>{{ findWorkPlace() == null ? '' : item.value }}℃</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 300,
      timerMax: 300,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromHaccpStoragePage',
    }),
    filtered_temp_sensor_list() {
      if (this.plc_sensor_logs.length < 1) {
        return [];
      } else {
        let filter = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(
            x => x.plc_sensor_detail_type_id == 1 && x.name.includes('실'),
          )
          .map(x => {
            let log = this.plc_sensor_logs.find(y => y.plc_sensor_id == x.id);
            x.plc_sensor_name = log.plc_sensor_name;
            x.plc_location_name = log.plc_location_name;
            x.value = log.value;
            x.create_time = log.create_time;
            return x;
          });
        return filter.sort((a, b) => a.id - b.id);
      }
    },
    checkWorkPlaceState() {
      const item = this.findWorkPlace();
      if (this.plc_sensors.length < 1) {
        return true;
      } else {
        if (item == null) {
          return true;
        }
        const foundSensor = this.plc_sensors.find(
          x => x.id == item.plc_sensor_id,
        );

        if (foundSensor == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(foundSensor.min_value) >
              this.$makeNumber(item.value) ||
            this.$makeNumber(foundSensor.max_value) <
              this.$makeNumber(item.value)
          ) {
            return false;
          } else return true;
        }
      }
    },
  },
  methods: {
    playWarningSound() {
      const audio = new Audio(require('@/assets/sound/warning_sound.mp3'));
      audio.play();
    },
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },

    findWorkPlace() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const WorkPlace = this.plc_sensor_logs.find(
          x => x.plc_sensor_type_id == 1,
        );
        return WorkPlace == undefined ? null : WorkPlace;
      }
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    // clearInterval(this.currentTimeInterval);
  },
  watch: {
    checkWorkPlaceState(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;
      console.log('test');
      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    // this.currentTimeInterval = setInterval(() => {
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
