<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>작업실 온도현황</h5>
        <div class="option_cont">
          <span class="option_txt">조회 기간</span>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="start_date"
              @change="setStartDate($event)"
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="end_date"
              @change="setEndDate($event)"
            />
          </div>
          <div class="input_text">
            <label>작업실 구분</label>
            <my-selectric
              :id="'locationSelectric'"
              :watch="searchLocation"
              :options="filterLocationType"
              :commit="'setSearchLocation'"
            >
            </my-selectric>
          </div>

          <button class="btn_sub2" @click="searchLog()">
            검색
          </button>
          <button
            class="excel_btn"
            v-show="filteredStorageTempStatus.length > 0"
            @click="exportExcel"
          >
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ searchList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>조회 날짜</th>
              <th>조회 시간</th>
              <th>작업실명</th>
              <th>온도</th>
              <th>조치사항</th>
              <th>조치사항 수정</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in searchList" :key="item.id">
              <td>{{ item.input_time.substr(0, 10) }}</td>
              <td>{{ item.input_time.substring(11, 19) }}</td>
              <td>
                {{ getStorageName(item.plc_sensor_id) }}
              </td>
              <td>{{ item.value }}℃</td>
              <td>
                <input
                  class="input_comment"
                  type="text"
                  :value="item.action_detail"
                  @input="$event => (item.action_detail = $event.target.value)"
                />
              </td>
              <td>
                <button class="btn_tbl" @click="submitRow(item)">수정</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import MODAL_MIXIN from '@/mixins/modal';
import MySelectric from '@/layouts/components/MySelectric';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN, SPINNER_MIXIN, MODAL_MIXIN],
  components: {
    MySelectric,
  },
  data() {
    return {
      searchList: [],
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_sensor_logs: 'getPlcSensorLog',
      searchLocation: 'getSearchLocation',
      start_date: 'getStartDateFromPlcSensorLog',
      end_date: 'getEndDateFromPlcSensorLog',
      plc_sensor_log_time: 'getPlcSensorLogTimes',
    }),

    filterLocationType() {
      let sensorLocationOptions = [];

      sensorLocationOptions.push({
        label: '작업실 전체',
        value: 0,
        detail: null,
      });
      this.plc_sensor_logs
        .filter(
          x => x.plc_location_type_id == 4 && x.plc_sensor_detail_type_id == 1,
        )
        .forEach(el => {
          sensorLocationOptions.push({
            label: this.getStorageName(el.plc_sensor_id),
            value: el.plc_sensor_id,
            detail: el.plc_location_type_id,
          });
        });
      return sensorLocationOptions;
    },

    filteredStorageTempStatus() {
      return this.lodash
        .clonedeep(this.plc_sensor_log_time)
        .filter(
          x =>
            x.plc_sensor_id == 1 ||
            x.plc_sensor_id == 3 ||
            x.plc_sensor_id == 5,
        )
        .filter(
          x =>
            9 <= Number(new Date(x.input_time).getHours()) &&
            Number(new Date(x.input_time).getHours()) <= 18,
        )
        .sort((a, b) => new Date(a.input_time) - new Date(b.input_time));
    },
  },
  methods: {
    async searchLog() {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_LOG_HACCP_TIME',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '작업실 현황',
      );
      if (this.searchLocation != 0) {
        this.searchList = this.filteredStorageTempStatus.filter(
          x => x.plc_sensor_id == this.searchLocation,
        );
      } else {
        this.searchList = this.filteredStorageTempStatus;
      }
    },

    getStorageName(plc_sensor_id) {
      let hitValue = this.plc_sensor_logs.find(
        x => x.plc_sensor_id == plc_sensor_id,
      );
      return hitValue == undefined
        ? ''
        : hitValue.plc_sensor_name.split('온습도센서')[0];
    },

    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },

    async submitRow(item) {
      this.showSpinner();
      const payload = this.lodash.clonedeep(item);
      this.$store
        .dispatch('INSERT_PLC_SENSOR_LOG_HACCP_TIME', payload)
        .then(() => {
          this.openOneButtonModal('수정 완료', '수정되었습니다.');
        })
        .catch(error => {
          console.log(error);
          {
            this.openOneButtonModal(
              '등록 실패',
              '작업실 온도 수정 중 오류 발생',
            );
          }
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    exportExcel() {
      let haccpTempPerHour = [];
      let date = this.start_date + ' ~ ' + this.end_date;
      let searchWorkPlaceName = this.filterLocationType.filter(
        x => x.value == this.searchLocation,
      )[0].label;
      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
        { s: { r: 4, c: 2 }, e: { r: 4, c: 6 } },
        // =========== 테이블 헤더 ==============
        { s: { r: 6, c: 0 }, e: { r: 6, c: 0 } },
        { s: { r: 6, c: 1 }, e: { r: 6, c: 1 } },
        { s: { r: 6, c: 2 }, e: { r: 6, c: 2 } },
        { s: { r: 6, c: 3 }, e: { r: 6, c: 3 } },
        { s: { r: 6, c: 4 }, e: { r: 6, c: 4 } },
        { s: { r: 6, c: 5 }, e: { r: 6, c: 5 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        ['작업실 구분', searchWorkPlaceName],
        ['최대온도: 19ºC이하'],
        [],
        [],
      ];

      haccpTempPerHour = haccpTempPerHour.concat(standardInfo);

      haccpTempPerHour.push([
        '조회일자',
        '시간',
        '작업실명',
        '온도',
        '조치사항',
        '서명',
      ]);

      this.searchList.forEach(el => {
        haccpTempPerHour.push([
          el.input_time.substr(0, 10),
          el.input_time.substring(11, 19),
          this.getStorageName(el.plc_sensor_id),
          el.value,
          el.action_detail,
        ]);
      });
      let wb = XLSX.utils.book_new();

      let excelData = XLSX.utils.json_to_sheet(haccpTempPerHour, {
        skipHeader: true,
      });

      excelData['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, excelData, '작업실 온도');

      let title = '작업실 온도(' + date + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();

      this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(date2));
    }
    if (this.plc_sensor_log_time.length == 0) {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_LOG_HACCP_TIME',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '작업실 현황',
      );
    }
    this.searchList = this.filteredStorageTempStatus;
  },
};
</script>
